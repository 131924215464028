export const useCallcenters = () => useState("callcenters", () => {
    const { $get } = useNuxtApp()
    let data;
    if (process.client) {
        const fetchData = async () => {
            try {
                const response = await $get('/api/protected/callcenter')
                data.value = response.data
                sessionStorage.setItem(`callcenters`, JSON.stringify(data.value))
            } catch (e) {
                error.value = e
            }
        }

            data = ref(JSON.parse(localStorage.getItem(`callcenters`)) || null)
            if (!data.value) {
                fetchData()
            }
    }
    return ref(data);
});